.brands-wrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.brands-container {
  display: flex;
  flex-wrap: nowrap;
  animation: scrollInfinite 30s linear infinite;
}

.brand-item {
  flex: 0 0 auto;
  margin: 0 20px;
}

.brand-image {
  width: 100%;
  height: auto;
  max-width: 150px;
}

@keyframes scrollInfinite {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
