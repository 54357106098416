@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Genos:ital,wght@0,100..900;1,100..900&display=swap");

.montserrat {
  font-family: "Montserrat", sans-serif, system-ui;
}

.button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
