$osdi-color: #1a659e; /* Color base */
$osdi-light: #95b8d1; /* Color claro */

/* Colores complementarios */
$osdi-light-gray: #d2d2d2; /* Gris suave */
$osdi-dark-gray: #464646; /* Gris oscuro */
$osdi-gold: #ffcc00; /* Dorado */

/* Colores vibrantes y llamativos */
$osdi-dark-blue: #004b75; /* Azul oscuro para contrastar */
$osdi-bright-blue: #00b0f0; /* Azul brillante que resalta */
$osdi-vibrant-pink: #ff4c6d; /* Rosa vibrante para acentos llamativos */
$osdi-light-green: #3ecf8e; /* Verde fresco y brillante */
$osdi-orange: #ff7f32; /* Naranja cálido y energizante */
$osdi-dark-red: #d32f2f; /* Rojo oscuro y fuerte */

/* Colores neutrales */
$white: #ffffff; /* Blanco */
$black: #000000; /* Negro */
