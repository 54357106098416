@use "/src/styles/colors";

.mainContainer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  background-color: colors.$white;
  overflow-x: hidden;
  font-family: "Genos", sans-serif;
}
