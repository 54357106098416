@use "/src/styles/colors";

.menu {
  & a {
    font-family: "Genos", sans-serif;
    font-size: 25px;
    font-weight: 450;
    text-decoration: none;
    display: inline-block;
    position: relative;
    transition: color 0.2s ease-in-out, border-bottom 0.2s ease-in-out;

    // Hover underline effect
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 0.5px;
      bottom: 0;
      left: 0;
      background-color: colors.$osdi-color;
      transform-origin: bottom right;
      transition: transform 0.2s ease-out;
    }

    // On hover, scale the underline
    &:hover:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }

    // Active link styles
    &.active {
      color: colors.$osdi-color; // For active color
      border-bottom: 1px solid colors.$osdi-color; // Active underline
    }
  }

  // Animations for fadeIn and fadeOut
  & .animate-fadeIn {
    animation: fadeIn 0.3s ease-in forwards;
  }

  & .animate-fadeOut {
    animation: fadeOut 0.3s ease-out forwards;
  }

  // Keyframes for fadeIn and fadeOut
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  // For mobile menu animations
  .fade-in {
    animation: fadeIn 0.3s ease-out;
  }

  .fade-out {
    animation: fadeOut 0.3s ease-in;
  }
}
